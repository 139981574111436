<template>
    <div>
        <v-col cols="12" md="6" class="mx-auto">
            <place-form ref="form" @submit="onSubmit()" v-model="model"/>
            <v-spacer/>
            <v-card-actions>
                <v-spacer/>
                <v-btn @click="onSubmit()" outlined ripple color="blue-grey darken-1" type="submit">
                    {{ $t(`actions['create place']`) }}
                </v-btn>
            </v-card-actions>
        </v-col>
    </div>
</template>

<script>
import { mapActions } from "vuex"
import PlaceForm from "../components/PlaceForm"

export default {
    name: "place-create",
    layout: "default",
    components: { PlaceForm },

    head() {
        return {
            title: this.$t(`titles['create place']`),
            toolbar: this.$t(`toolbar['create place']`),
        }
    },

    data() {
        return {
            model: {},
        }
    },

    fetch({ store, redirect }) {
        if (!store.getters["common/isJesus"]) {
            return redirect("/forbidden")
        }
    },

    methods: {
        ...mapActions({ createPlace: "place/create" }),
        onSubmit() {
            if (!this.$refs.form.validate()) {
                return
            }
            this.onSubmitForm()
        },

        onSubmitForm() {
            this.$dialog.loading({ text: this.$t(`messages['place create in progress']`) }).then((dialog) => {
                const close = () => dialog.close()
                this.createPlace(this.model).then(res => {
                    this.$router.push(`/places/${res.ID}`, close, close)
                }).catch(() => {
                    this.$dialog.message.error(this.$t(`errors['place create error']`))
                }).finally(close)
            })
        },
    },
}
</script>
